import { FC, useCallback, useState, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import A from 'utils/metrics/analytic'
import GTAG from 'utils/metrics/gtagMethods'
import PopupWrapperNew from 'containers/popup-wrapper-new/popup-wrapper-new'
import Loader from 'components/parts/loader/loader'
import LoginPopup from 'components/fullscreen-popups/login-form/login-form'
const RecoverPassword = dynamic(() => import('../recover-password/recover-password'))
const RecoverSuccess = dynamic(() => import('../recover-success/recover-success'))
const RegistrationOlympPopup = dynamic(() => import('../registration/registrationOlymp'))
const RegistrationGeriatricsSchool = dynamic(
  () => import('../registration/registrationGeriatricsSchool')
)
const RegistrationStep2 = dynamic(
  () =>
    import(
      'components/fullscreen-popups/registration/double-registration/parts/registration-step2/registration-step2'
    )
)
const AnketaWebinar = dynamic(
  () => import('components/fullscreen-popups/registration/anketa-webinar/anketa-webinar'),
  {
    loading: () => (
      <div className={styles.loaderWrapper}>
        <Loader modificator={styles.loaderMod} />
      </div>
    ),
  }
)
const RegistrationPopup = dynamic(
  () => import('components/fullscreen-popups/registration/double-registration/double-registration')
)
const RegistrationMagnit = dynamic(
  () => import('components/fullscreen-popups/registration/registrationMagnit')
)
import Auth from 'store/auth'
import useActivePopupStatus from 'store/common/useActivePopupStatus'
import useTitle from 'store/common/useTitle'

import { YaMetricHit } from 'utils/metrics/analyticMethods'
import { cutHashFromURL } from 'utils/getURLData'
import { popupName } from 'constants/auth/auth-data'
import { AdditionPageTitle, LoginHashPopup, RegHashPopup, TransitionEvent } from './data'
import { SessionPopupsKeys } from 'constants/storage'

import styles from './hash-popup.module.scss'

let isBottom = false
const HashPopup: FC = () => {
  const { isAuthorized, isFilledUser } = Auth.useAuth()
  const { hashPopupStatus, setHashPopupStatus } = useActivePopupStatus()
  const { setPageTitle } = useTitle()
  const router = useRouter()
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  const path = cutHashFromURL(router.asPath)

  const handleClose = useCallback(() => {
    hashPopupStatus && setHashPopupStatus()
    router.replace(path, undefined, { shallow: true })
  }, [])

  const afterLoginHandlerGeriatricsSchool = () => {
    sessionStorage.setItem(SessionPopupsKeys.GERIATRICS_SCHOOL_GER_POPUP, 'true')
  }

  const currentPop = useMemo(() => {
    let content = null
    isBottom = false
    if (LoginHashPopup.includes(hashPopupStatus)) {
      isBottom = true
      content = <LoginPopup handleClose={handleClose} />
    } else if (hashPopupStatus === popupName.loginGeriatricsSchool)
      content = <LoginPopup onSubmitEnd={afterLoginHandlerGeriatricsSchool} />
    else if (hashPopupStatus === popupName.geriatricsSchool)
      content = <RegistrationGeriatricsSchool handleClose={handleClose} />
    else if (hashPopupStatus === popupName.recoverPassword) {
      isBottom = true
      content = <RecoverPassword />
    } else if (hashPopupStatus === popupName.recoverSuccess) {
      content = <RecoverSuccess />
    } else if (hashPopupStatus === popupName.nopassRegistration) {
      content = <RegistrationMagnit handleClose={handleClose} />
    } else if (RegHashPopup.includes(hashPopupStatus)) {
      content = <RegistrationPopup handleClose={handleClose} />
    } else if (hashPopupStatus === popupName.registrationWithSteps)
      content = <RegistrationPopup handleClose={handleClose} />
    else if (hashPopupStatus === popupName.anketa && isAuthorized && !isFilledUser)
      content = <RegistrationStep2 handleClose={handleClose} />
    else if (hashPopupStatus === popupName.anketaWebinar && isAuthorized && !isFilledUser) {
      isBottom = true
      content = <AnketaWebinar handleClose={handleClose} />
    } else if (hashPopupStatus === popupName.registratiomStudentsOlymp)
      content = <RegistrationOlympPopup handleClose={handleClose} />

    return content
  }, [handleClose, hashPopupStatus, isAuthorized, isFilledUser])

  useEffect(() => {
    const isContent = Boolean(currentPop)
    if (isContent) {
      A.setEvent(TransitionEvent[hashPopupStatus as keyof typeof TransitionEvent])
    }
    const nextPopupStatus =
      (isContent && AdditionPageTitle[hashPopupStatus as keyof typeof AdditionPageTitle]) || ''

    setPageTitle(nextPopupStatus)
    GTAG.pageview(window.location.href)
    YaMetricHit(window.location.href)

    setComponent(currentPop)
  }, [hashPopupStatus, isAuthorized, handleClose, isFilledUser, currentPop, setPageTitle])

  const isComponentActive = Boolean(hashPopupStatus) && Boolean(component)

  if (!isComponentActive) return null

  if (RegHashPopup.includes(hashPopupStatus)) return <>{component}</>

  return (
    <PopupWrapperNew
      popupBlockMod={styles.popupHelper}
      bgNoClicked
      isBottom={isBottom}
      closePopup={handleClose}
    >
      {component}
    </PopupWrapperNew>
  )
}

export default HashPopup
