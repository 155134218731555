// Когда полностью перенесем все на popup-wrapper-new, то нужно будет удалить компонент popup-wrapper , а компонент popup-wrapper-new - переименовать в popup-wrapper
import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'

import usePopupScrollPosition from '../../hooks/usePopupScrollPosition'
import BtnClose from 'public/img/filters/icon_close.svg'

const PortalWrap = dynamic(() => import('../portal-wrap/portal-wrap'), { ssr: false })

import styles from './popup-wrapper-new.module.scss'

interface IPopupWrapperNew {
  children: ReactNode
  closePopup?: (e: React.MouseEvent<HTMLDivElement>) => void
  isFullScreen?: boolean
  isBottom?: boolean
  isPopupScrollPosition?: boolean
  btnCloseMod?: string
  popupBlockMod?: string
  bgMod?: string
  noPortal?: boolean
  bgNoClicked?: boolean
}

const PopupWrapperNew: FC<IPopupWrapperNew> = ({
  children,
  closePopup,
  isPopupScrollPosition = true,
  isFullScreen = true,
  isBottom = false,
  noPortal = false,
  btnCloseMod,
  popupBlockMod,
  bgMod,
  bgNoClicked = false,
}) => {
  usePopupScrollPosition(isPopupScrollPosition)

  const bgHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (bgNoClicked) return
    closePopup?.(e)
  }

  const popupContent = () => {
    return (
      <div
        className={clsx(styles.popupWrapper, {
          [bgMod as string]: bgMod,
        })}
      >
        <div className={styles.popupBG} onClick={bgHandler}></div>

        <div
          className={clsx(styles.popupBlock, {
            [styles.fullScreens]: isFullScreen,
            [styles.bottomScreens]: isBottom,
            [popupBlockMod as string]: popupBlockMod,
          })}
        >
          {closePopup && (
            <div onClick={closePopup}>
              <BtnClose
                className={clsx(styles.btnClose, {
                  [btnCloseMod as string]: btnCloseMod,
                })}
              />
            </div>
          )}
          {children}
        </div>
      </div>
    )
  }
  return <>{noPortal ? popupContent() : <PortalWrap>{popupContent()}</PortalWrap>}</>
}

export default PopupWrapperNew
